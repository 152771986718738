import React, { useEffect, useState, useMemo } from 'react'

import * as styles from './crudItems.module.scss'
import { Input, Textarea, Dropdown, DateInput } from '../form/fields'
import { DropdownWrapper } from './dropdownWrapper'
import { Image, ImageViewer, Svg } from '../../common'
import { ToolTypes } from '../../../types'
import {
   formatUsDate,
   currencyFormatter,
   parseDate,
   formatLocaleDate,
   truncate,
} from '../../../common/utils/functions'
import { Actions } from '../../../constants/tables'
import classNames from 'classnames'
import { executeReverseGeocodeLookup } from './../map/mapHelperFunctions'
import { NumericFormat } from 'react-number-format'
import { ItemDetailsProps } from './types'

export const ItemDetails = ({
   activeValue,
   itemTypes,
   itemForm,
   categories,
   locations,
   manufacturers,
   updateForm,
   updateFormSelect,
   itemAction,
   isTitleError,
   showEditItems,
   isQuantityOrConsumableToolType,
   isQuantityItem,
   settings,
   isEmployeeUser,
   imagesForGallery,
}: ItemDetailsProps) => {
   const { Id, StatusDesc, StatusQuantity, CurrentLocation, AssignedToUser } =
      activeValue

   const [displayLocation, setDisplayLocation] = useState<boolean>(false)

   const toolTypesArr =
      isEmployeeUser && itemAction !== Actions.Readonly
         ? itemTypes.filter((iType) => iType?.label === 'Standard Item')
         : itemTypes

   const [defaultType, setDefaultType] = useState({
      value: itemForm.ToolType,
      label: ToolTypes[itemForm.ToolType],
   })
   const [defaultManufacturer, setDefaultManufacturer] = useState({
      value: itemForm.ManufacturerId || 0,
      label: itemForm.Manufacturer || '',
   })

   const [defaultCategory, setDefaultCategory] = useState({
      value: itemForm.CategoryID || 0,
      label: itemForm.Category || '',
   })
   const [defaultLocation, setDefaultLocation] = useState(
      itemForm.DefaultLocationID
         ? {
              value: itemForm.DefaultLocationID || 0,
              label: itemForm.DefaultLocation,
           }
         : null
   )

   const [reverseGeoLookUpResults, setReverseGeoLookupResults] =
      useState<string>('')

   const [openImageViewer, setOpenImageViewer] = useState<boolean>(false)

   useEffect(() => {
      if (itemForm.GPS?.Latitude && itemForm.GPS?.Longitude) {
         executeReverseGeocodeLookup(
            { lat: itemForm.GPS?.Latitude, lng: itemForm.GPS?.Longitude },
            setReverseGeoLookupResults
         )
      }
   }, [itemForm])

   useEffect(() => {
      updateFormSelect({
         ...itemForm,
         ManufacturerId: defaultManufacturer.value || 132, // 132 = unspecified
         ToolType: defaultType.value,
         ToolTypeDesc: defaultType.label,
         Manufacturer: defaultManufacturer.label || 'Unspecified',
         Category: defaultCategory.label || 'Uncategorized',
         CategoryID: defaultCategory.value || 132,
         DefaultLocationID: defaultLocation?.value,
         DefaultLocation: defaultLocation?.label,
      })
   }, [defaultType, defaultManufacturer, defaultLocation, defaultCategory])

   useEffect(() => {
      if (settings?.EnableLocationTracking) {
         setDisplayLocation(true)
      }
   }, [settings?.EnableLocationTracking])

   const handleDropdownChange = (
      name: { value: string | number; label: string },
      type: { name: string }
   ) => {
      if (name === null) return
      const { value: rawValue, label } = name
      // fix type error: convert all values to a string
      const value = String(rawValue)

      switch (type?.name) {
         case 'Type':
            setDefaultType({ value, label })
            break
         case 'Manufacturer':
            setDefaultManufacturer({
               value,
               label,
            })
            break
         case 'Category':
            setDefaultCategory({ value, label })
            break
         case 'Location':
            setDefaultLocation({ value, label })
            break
         default:
      }
   }

   // called from onBlur of select component
   const handleGetDropdownTypedInput = (
      textValue: string,
      dropdownName: string
   ) => {
      switch (dropdownName) {
         case 'Category':
            setDefaultCategory((prevCategory) =>
               textValue ? { value: '', label: textValue } : { ...prevCategory }
            )
            break
         case 'Manufacturer':
            setDefaultManufacturer((prevManufacturer) =>
               textValue
                  ? { value: '', label: textValue }
                  : { ...prevManufacturer }
            )
            break
         case 'Location': // onBlur the location just needs resetting to it's current value
            setDefaultLocation((prevLocation) => ({ ...prevLocation })) // allows an update in state to re-render the select value container
            break
         default:
            break
      }
   }

   const classes = classNames(styles.status, {
      [styles.statusAvailable]: StatusDesc === 'Available',
      [styles.statusPending]: StatusDesc === 'Pending',
      [styles.statusLoaned]: StatusDesc === 'Loaned',
      [styles.statusBorrowed]: StatusDesc === 'Borrowed',
      [styles.statusUnavailable]: StatusDesc === 'Unavailable',
   })

   const baseProps = {
      className: classes,
   }

   const checkTotal = (value) => (value.includes('NaN') ? '$0.00' : value)

   const displayDueBackDate = useMemo(() => {
      return !itemForm?.Borrower?.UserID ? false : true
   }, [itemForm])

   const itemHasImages = useMemo(
      () => imagesForGallery?.length > 0,
      [imagesForGallery]
   )

   return (
      <>
         <div
            className={styles.itemColumns}
            style={{
               pointerEvents: itemAction === Actions.Readonly ? 'none' : 'auto',
            }}
         >
            <div className={styles.columnOneThree}>
               <div
                  className={styles.itemMainImage}
                  onClick={() => setOpenImageViewer(itemHasImages)}
                  style={{ pointerEvents: itemHasImages ? 'auto' : 'none' }}
               >
                  <Image
                     width={'100%'}
                     height={'100%'}
                     url={itemHasImages ? imagesForGallery[0].src : null}
                     alt={itemForm?.Title && itemForm?.Title}
                  />
                  {itemHasImages && (
                     <svg
                        className={styles.zoomInIcon}
                        xmlns="http://www.w3.org/2000/svg"
                        height="20"
                        width="20"
                        viewBox="0 0 512 512"
                     >
                        <path d="M208 48a160 160 0 1 1 0 320 160 160 0 1 1 0-320zm0 368c48.8 0 93.7-16.8 129.1-44.9L471 505c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L371.1 337.1C399.2 301.7 416 256.8 416 208C416 93.1 322.9 0 208 0S0 93.1 0 208S93.1 416 208 416zM184 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z" />
                     </svg>
                  )}
               </div>
               <ImageViewer
                  open={openImageViewer}
                  setOpen={setOpenImageViewer}
                  images={itemHasImages ? imagesForGallery : []}
               />

               {Actions.Add === itemAction ? (
                  <>
                     <Dropdown
                        defaultValue={defaultType}
                        isMulti={false}
                        id="Type"
                        name={`Type`}
                        onChange={handleDropdownChange}
                        options={toolTypesArr}
                        isClearable={false}
                        withWrappers={false}
                        placeholder="Type *"
                     />

                     <br />
                  </>
               ) : (
                  <Input
                     id={`${Id}a`}
                     label="Type *"
                     disabled
                     value={ToolTypes[defaultType.value]}
                  />
               )}

               <Input
                  id={`${Id}0`}
                  onChange={(e) => updateForm('Title', e.target.value)}
                  label="Item Name"
                  required
                  error={isTitleError}
                  value={itemForm.Title}
               />

               <Textarea
                  id={`${Id}2`}
                  label="Item Detail"
                  onChange={(e) => updateForm('Description', e.target.value)}
                  height={125}
                  value={itemForm.Description}
               />

               {isQuantityOrConsumableToolType && (
                  <Input
                     id={`${Id}1`}
                     onChange={(e) => updateForm('Quantity', e.target.value)}
                     label="Total Quantity"
                     value={itemForm.Quantity}
                     inputProps={{
                        type: 'number',
                     }}
                     placeholder={'-'}
                  />
               )}
            </div>
            <div className={styles.columnTwoThree}>
               <section className={styles.detailsPanel}>
                  {showEditItems && (
                     <>
                        <section className={styles.itemDetailsBlock}>
                           <div className={styles.itemDetail}>
                              <span>
                                 {StatusDesc ? (
                                    <span {...baseProps}>
                                       {isQuantityOrConsumableToolType &&
                                          StatusQuantity}{' '}
                                       {StatusDesc}
                                    </span>
                                 ) : (
                                    '-'
                                 )}
                              </span>
                           </div>
                           <div className={styles.itemDetail}>
                              <div>
                                 {AssignedToUser && (
                                    <span className={styles.itemDetailValue}>
                                       {`${AssignedToUser}${
                                          CurrentLocation && displayLocation
                                             ? ', '
                                             : ''
                                       } `}
                                    </span>
                                 )}
                                 <span className={styles.itemDetailValue}>
                                    {CurrentLocation && displayLocation
                                       ? CurrentLocation
                                       : ''}
                                 </span>
                              </div>
                           </div>
                           <div className={styles.itemDetail}>
                              {displayDueBackDate && (
                                 <>
                                    <span className={styles.itemDetailLabel}>
                                       Due Back:&nbsp;
                                    </span>
                                    <span className={styles.itemDetailValue}>
                                       {itemForm?.Borrower?.ReturnDue.includes(
                                          '2099'
                                       ) || !itemForm?.Borrower?.ReturnDue
                                          ? '-'
                                          : formatLocaleDate(
                                               itemForm?.Borrower?.ReturnDue
                                            )}
                                    </span>
                                 </>
                              )}
                           </div>
                        </section>
                        <section className={styles.itemDetailsBlock}>
                           <div className={styles.itemDetail}>
                              <span className={styles.itemDetailLabel}>
                                 Item Created&nbsp;
                              </span>
                              <span className={styles.itemDetailValue}>
                                 {itemForm?.CreatedDate
                                    ? formatLocaleDate(itemForm?.CreatedDate)
                                    : '-'}
                              </span>
                           </div>
                           <div className={styles.itemDetail}>
                              <span className={styles.itemDetailLabel}>
                                 Last Audit&nbsp;
                              </span>
                              {itemForm?.LastAuditDate ? (
                                 <>
                                    <span className={styles.itemDetailValue}>
                                       {truncate(itemForm?.LastAuditBy, 16)}
                                       &nbsp;
                                    </span>
                                    <span className={styles.itemDetailLabel}>
                                       on&nbsp;
                                    </span>
                                    <span className={styles.itemDetailValue}>
                                       {formatLocaleDate(
                                          itemForm.LastAuditDate
                                       )}
                                    </span>
                                 </>
                              ) : (
                                 <span className={styles.itemDetailValue}>
                                    {'-'}
                                 </span>
                              )}
                           </div>
                           <div className={styles.itemDetail}>
                              <span className={styles.itemDetailLabel}>
                                 Last Reported Condition:&nbsp;
                              </span>
                              <span className={styles.itemDetailValue}>
                                 {itemForm?.LastAuditConditionDesc
                                    ? itemForm?.LastAuditConditionDesc
                                    : '-'}
                              </span>
                           </div>
                        </section>
                     </>
                  )}
               </section>

               <DropdownWrapper action={setDefaultManufacturer}>
                  <Dropdown
                     defaultValue={defaultManufacturer}
                     isMulti={false}
                     id={`${Id}a1`}
                     name={`Manufacturer`}
                     onChange={handleDropdownChange}
                     onTextChange={handleGetDropdownTypedInput}
                     options={manufacturers?.length ? manufacturers : []}
                     withWrappers={false}
                     placeholder={'Select Manufacturer'}
                     menuPlacement="auto"
                  />
               </DropdownWrapper>
               <br />

               <Input
                  id={`${Id}4`}
                  label="Model Number"
                  onChange={(e) => updateForm('ModelNumber', e.target.value)}
                  value={itemForm.ModelNumber}
               />
               <Input
                  id={`${Id}5`}
                  label="Serial Number"
                  onChange={(e) => updateForm('SerialNumber', e.target.value)}
                  value={itemForm.SerialNumber}
                  disabled={isQuantityOrConsumableToolType}
               />

               <Input
                  id={`${Id}7`}
                  label="Barcode"
                  onChange={(e) => updateForm('Barcode', e.target.value)}
                  value={itemForm.Barcode}
                  disabled={isQuantityItem}
               />

               {isQuantityOrConsumableToolType && !isQuantityItem && (
                  <Input
                     id={`${Id}8`}
                     onChange={(e) =>
                        updateForm('ReorderLevelQuantity', e.target.value)
                     }
                     label="Reorder Level"
                     value={itemForm.ReorderLevelQuantity}
                     inputProps={{
                        type: 'number',
                     }}
                     placeholder={'-'}
                  />
               )}
            </div>

            <div className={styles.columnThreeThree}>
               <div className={styles.detailsPanelTrackingInfo}>
                  {showEditItems && itemForm?.GPS && (
                     <section className={styles.itemDetailsBlock}>
                        <div className={styles.itemDetail}>
                           <div className={styles.itemDetailLabel}>
                              <Svg id="gps" fill={'#96999b'} />
                              Last GPS Scan
                              <span className={styles.itemDetailValue_lat_lng}>
                                 {`${itemForm?.GPS?.Latitude} \u00A0\u00A0 ${itemForm?.GPS?.Longitude}`}
                              </span>
                           </div>
                           <div>
                              <span className={styles.itemDetailValue}>
                                 {itemForm?.GPS
                                    ? itemForm?.GPS.CreatedByName
                                    : '-'}
                              </span>
                              <span className={styles.itemDetailLabel}>
                                 &nbsp;on&nbsp;
                              </span>
                              <span className={styles.itemDetailValue}>
                                 {itemForm.GPS?.CreatedOn
                                    ? formatLocaleDate(itemForm.GPS?.CreatedOn)
                                    : '-'}
                              </span>
                           </div>
                           <div>
                              <span className={styles.itemDetailLabel}>
                                 Near{' '}
                              </span>
                              <span className={styles.itemDetailValue}>
                                 {reverseGeoLookUpResults ?? 'N/A'}
                              </span>
                           </div>
                        </div>
                     </section>
                  )}
                  {/* {(showEditItems && itemForm?.Bluetooth) && (
                        <section className={styles.itemDetailsBlock}>
                           <div className={styles.itemDetail}>
                              <div className={styles.itemDetailLabel}>
                                 <Svg id="bluetooth" fill="#96999b" />Last Bluetooth Ping
                              </div>
                              <div>
                                 <span className={styles.itemDetailValue}>
                                    {itemForm?.GPS ? itemForm?.GPS : 'John Doe'}
                                 </span>
                                 <span className={styles.itemDetailLabel}>
                                    &nbsp;on&nbsp;
                                 </span>
                                 <span className={styles.itemDetailValue}>
                                    {itemForm.GPS?.date
                                       ? itemForm?.GPS?.date
                                       : '-'}
                                 </span>
                              </div>
                              <div>
                                 <span className={styles.itemDetailValue}>
                                    {`35.22732 -80.84213`}
                                 </span>
                              </div>
                           </div>
                        </section>
                        )} */}
               </div>

               <DropdownWrapper action={setDefaultCategory}>
                  <Dropdown
                     defaultValue={defaultCategory}
                     isMulti={false}
                     id={`${Id}a1`}
                     name={`Category`}
                     onChange={handleDropdownChange}
                     onTextChange={handleGetDropdownTypedInput}
                     options={categories?.length ? categories : []}
                     withWrappers={false}
                     placeholder={'Select Category'}
                     menuPlacement="auto"
                  />
               </DropdownWrapper>
               <br />

               {settings.EnableLocationTracking && (
                  <>
                     <DropdownWrapper action={setDefaultLocation}>
                        <Dropdown
                           defaultValue={defaultLocation}
                           isMulti={false}
                           id={`${Id}a`}
                           name={`Location`}
                           onChange={handleDropdownChange}
                           onTextChange={handleGetDropdownTypedInput}
                           options={locations}
                           withWrappers={false}
                           placeholder="Select Return Warehouse"
                        />
                     </DropdownWrapper>
                     <br />
                  </>
               )}

               {!isQuantityOrConsumableToolType ? (
                  <>
                     <DateInput
                        id={`${Id}91`}
                        label="Purchase Date"
                        maxDate={new Date()}
                        onChange={(e) => updateForm('PurchaseDate', e)}
                        selectedDate={parseDate(itemForm.PurchaseDate) || null}
                        inputProps={{
                           type: 'number',
                        }}
                     />
                  </>
               ) : (
                  <>
                     <Input
                        id={`${Id}9112`}
                        label={'Purchase Date'}
                        value={formatUsDate(itemForm.PurchaseDate)}
                        disabled
                     />
                  </>
               )}

               <div className={styles.currency}>
                  <span />
                  <NumericFormat
                     customInput={Input}
                     onChange={(e) =>
                        updateForm('PurchasePrice', e.target.value)
                     }
                     id={`${Id}1112`}
                     label="Purchase Price"
                     value={itemForm.PurchasePrice || ''}
                     decimalScale={2}
                     allowNegative={false}
                     maxLength={'11'}
                  />
               </div>

               {isQuantityOrConsumableToolType ? (
                  <div className={styles.totalValue}>
                     <div className={styles.totalValueKey}>Total Value: </div>
                     &nbsp;
                     {checkTotal(
                        currencyFormatter(
                           itemForm.PurchasePrice * (itemForm.Quantity || 1)
                        )
                     )}
                  </div>
               ) : (
                  <div className={styles.totalValue}>
                     <div className={styles.totalValueKey}>Total Value: </div>
                     &nbsp;
                     {checkTotal(
                        currencyFormatter(itemForm.PurchasePrice || 0)
                     )}
                  </div>
               )}
            </div>
         </div>
      </>
   )
}
