import React, { ReactNode } from 'react'

import * as styles from './noResults.module.scss'

type NoResultsProps = {
   children?: ReactNode
}

const NoResults = ({ children }: NoResultsProps) => (
   <div className={styles.wrapper}>{children}</div>
)

export default NoResults
