import React from 'react'

import { PageHeaderProps } from './types'
import classNames from 'classnames'
import * as styles from './pageHeader.module.scss'
import { Seo } from '..'

const PageHeader = ({
   children,
   className,
   metaTitle,
   subtitle,
   title,
   variant,
}: PageHeaderProps) => {
   const classes = classNames(
      styles.pageHeader,
      {
         [styles.wideMargin]: variant === 'wideMargin',
      },
      className
   )

   return (
      <div className={classes}>
         <Seo title={metaTitle || title} />
         <h1 className={styles.pageHeaderTitle}>{title}</h1>
         <div className={styles.pageHeaderRight}>
            {subtitle ? (
               <div className={styles.pageHeaderSubtitle}>{subtitle}</div>
            ) : (
               children
            )}
         </div>
      </div>
   )
}

export default PageHeader
